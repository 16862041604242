export interface Palette {
    background: string
    progressStroke: string
    progressTrail: string
    weeksText?: string
    weeksOpacity?: number
}

const BOY_PALETTE: Palette = {
    background: "#334173",
    progressStroke: "#369AFE",
    progressTrail: "#FFFFFF66",
}
const GIRL_PALETTE: Palette = {
    background: "#FFDAFC",
    progressStroke: "#FF3974",
    progressTrail: "#FFFFFF",
    weeksText: "#FF00B5",
    weeksOpacity: .4,
}
const NEUTRAL_PALETTE: Palette = {
    background: "#313541",
    progressStroke: "#222222",
    progressTrail: "#FFFFFF66",
}

export { BOY_PALETTE, GIRL_PALETTE, NEUTRAL_PALETTE };
