import csstype from 'csstype';
import { FunctionComponent } from 'react';

type HeartProps = {
    className?: string,
    color?: string,
    width?: number,
    position?: csstype.Property.Position,
    top?: number,
    left?: number,
}

export const Heart: FunctionComponent<HeartProps> = ({
    className = "heart",
    color = "white",
    width = 100,
    position = "absolute",
    top = 0,
    left = 0,
}) => {
    return (
        <div
            className={className}
            style={{
                position: position,
                top: top,
                left: left,
            }}
        >
            <svg width={width} viewBox={`0 0 568 489`}>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M47.2833967,446.690619 L47.2833967,446.690619 C109.980611,509.387833 211.58974,498.155886 274.286954,435.458672 L284.0584,425.722 L293.795073,435.458672 C356.492287,498.155886 458.101416,509.387833 520.79863,446.690619 L520.79863,446.690619 C583.461071,384.028179 583.461071,282.384276 520.79863,219.721835 L511.027184,209.950389 L313.963894,12.8870985 C297.446325,-3.66524424 270.635702,-3.66524424 254.118133,12.8870985 L57.0548427,209.950389 L47.2833967,219.721835 C-15.3790437,282.384276 -15.3790437,384.028179 47.2833967,446.690619" id="path290" fill={color} fillRule="nonzero" transform="translate(284.041013, 244.472841) scale(-1, 1) rotate(-180.000000) translate(-284.041013, -244.472841) "></path>
                </g>
            </svg>
        </div>
    )
}
