import { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import { Palette } from '../palettes';
import { Genders } from '../App';
import dogImg from '../images/dog.svg';
import storkImg from '../images/baby-stork.svg';
import storkBoyImg from '../images/baby-stork-boy.svg';
import pregnancyImg from '../images/pregnancy.svg';
import programmerImg from '../images/programmer-computer.svg';
import { Progress } from 'antd';

const PREGNANCY_TOTAL_WEEKS = 40;
const PREGNANCY_TOTAL_DAYS = PREGNANCY_TOTAL_WEEKS * 7;
const REFRESH_INTERVAL = 1 * 1000; // 1s

type GenderStorkImg = { [G in Genders]: string };

type FrameProps = {
    palette: Palette,
    pregnancyStart: Date,
    gender?: Genders,
}

export const Frame: FunctionComponent<FrameProps> = ({
    palette,
    pregnancyStart,
    gender,
}) => {
    const allGenderStorks: GenderStorkImg = {
        [Genders.Boy]: storkBoyImg,
        [Genders.Girl]: storkImg, // FIXME: fix image when when we have a stork girl version
    }

    const [today, setToday] = useState(moment(new Date()));

    useEffect(() => {
        const interval = setInterval(() => setToday(moment(new Date())), REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const currentDays = today.diff(pregnancyStart, 'days');
    const weeks = Math.floor(currentDays / 7);
    const days = currentDays - (weeks * 7);
    const percent = currentDays / PREGNANCY_TOTAL_DAYS * 100;
    let selectedStork = storkImg
    if (gender) {
        selectedStork = allGenderStorks[gender];
    }

    return (
        <div className="frame">
            <div className="dog-container">
                <img src={dogImg} alt="dog" />
            </div>
            <div className="pregnancy-container">
                <img src={pregnancyImg} alt="pregnancy" />
            </div>
            <div className="stork-container">
                <img src={selectedStork} alt="stork" width="150wh" />
            </div>
            <div className="weeks-container" style={{
                color: palette.weeksText,
                opacity: palette.weeksOpacity,
            }}>
                <span className="weeks">{weeks}w</span>
                {days > 0 ?
                    <span className="days">{days}d</span>
                    : ''}
            </div>

            <div className="programmer-container">
                <img src={programmerImg} alt="programmer" />
            </div>
            <div className="progress-container">
                <Progress percent={percent} showInfo={false} strokeColor={palette.progressStroke} trailColor={palette.progressTrail} />
            </div>
        </div>
    )
}
