import { FunctionComponent, useState, useEffect } from 'react';
import { Heart } from './Heart';
import './HeartsGenerator.css';

const COLORS = [
    "#ff0000", "#ff4000", "#ff8000", "#ffbf00", "#ffff00", "#bfff00", "#80ff00", "#40ff00",
    "#00ff00", "#00ff40", "#00ff80", "#00ffbf", "#00ffff", "#00bfff", "#0080ff", "#0040ff",
    "#0000ff", "#4000ff", "#8000ff", "#bf00ff", "#ff00ff", "#ff00bf", "#ff0080", "#ff0040",
    "#ff0000",
] as const;

const HEART_ANIMATION_INTERVAL = 10 * 1000; // 10s
const CHECK_HEARTS_INTERVAL = 1 * 1000; // 1s
const MAX_HEARTS = 10;
const HEIGHT_RATIO = 0.86;
const WIDTH_MIN = 10;
const WIDTH_MAX = 70;

type HeartsGeneratorProps = {

};

export const HeartsGenerator: FunctionComponent<HeartsGeneratorProps> = ({
}) => {
    const [currentHearts, setCurrentHearts] = useState([] as React.ReactElement[]);

    const checkHearts = () => {
        if (currentHearts.length >= MAX_HEARTS) {
            currentHearts.shift();
        }
        currentHearts.push(<WrapHeart key={Math.random()} />);
        setCurrentHearts([...currentHearts]);
    }

    useEffect(() => {
        const interval = setInterval(checkHearts, CHECK_HEARTS_INTERVAL);
        return () => { clearInterval(interval) };
    }, [setCurrentHearts, checkHearts]);

    return <>{currentHearts}</>;
}

const WrapHeart = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => setVisible(false), HEART_ANIMATION_INTERVAL);
        return () => { clearInterval(interval) };
    }, []);

    const currentColor = COLORS[Math.floor(Math.random() * COLORS.length)];
    const width = getRandomInt(WIDTH_MIN, WIDTH_MAX);
    const documentWidth = document.documentElement.clientWidth;
    const documentHeight = document.documentElement.clientHeight;
    return (visible ?
        <Heart
            className="animated-heart"
            top={documentHeight - (width * HEIGHT_RATIO)}
            width={width}
            left={getRandomInt(0 - (width / 2), documentWidth - (width / 2))}
            color={currentColor}
        />
        : null
    )
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}
