import './App.css';
import { BOY_PALETTE, GIRL_PALETTE, NEUTRAL_PALETTE, Palette } from './palettes';
import { HeartGenerator, Frame } from './components';

const PREGNANCY_START = "2023-01-11T00:00:00";

export enum Genders {
  Boy = "boy",
  Girl = "girl",
}

type GenderPalettes = { [G in Genders]: Palette };
interface AllGenderPalettes extends GenderPalettes {
  "default": Palette
}

const paletteSelector: AllGenderPalettes = {
  [Genders.Boy]: BOY_PALETTE,
  [Genders.Girl]: GIRL_PALETTE,
  "default": NEUTRAL_PALETTE,
}

function App() {
  const searchParams = new URLSearchParams(document.location.search);
  const gender: Genders = searchParams.get("gender") as Genders;
  const pregnancyStart = new Date(searchParams.get("pregnancyStart") || PREGNANCY_START);

  let currentPalette = paletteSelector.default;
  if (gender in paletteSelector) {
    currentPalette = paletteSelector[gender as Genders];
  }

  return (
    <div className="page" style={{ backgroundColor: currentPalette.background }}>
      <Frame palette={currentPalette} pregnancyStart={pregnancyStart} gender={gender} />
      <HeartGenerator />
    </div>
  );
}

export default App;
